import { useCallback, useState, useRef } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  removeSignAsset,
  saveAssets,
} from '../../../redux/actions/AssetActions'
import { ASSET_LIBRARY, ASSET_UPLOAD_TYPE } from '../../../constants'
import UploadBox from '../../upload-box/UploadBox'
import SelectRadio from '../select-radio/SelectRadio'
import LibraryAssetUploader from '../../../services/library-assets/LibraryAssetUploader'
import FileUtils from '../../../utils/FileUtils'
import { AGREEMENT } from '../../../constants/Routes'
import TikBoxModal from '../modal/Modal'
import UploadBoxStatus from '../upload-box-status/UploadBoxStatus'
import {
  getAssetInMB,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'

const AssetsUploadSection = ({ template }) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [uploadAssetType, setUploadAssetType] = useState(
    ASSET_UPLOAD_TYPE.SINGLE,
  )
  const [assetCopyright, setAssetCopyright] = useState(false)
  const [assetType, setAssetType] = useState('')
  const [openUploadsModal, setOpenUploadsModal] = useState(false)
  const {
    auth: { user },
    assets: { signing: signingAssets },
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const assetsUploadProgresses = useRef([])

  const changeUploadAssetType = (type) => {
    if (type !== uploadAssetType) {
      triggerGoogleAnalyticsEvent('upload_asset_type', {
        type,
        userId: user?.id,
      })
      setUploadAssetType(type)
      resetAssetUplaoded(null)
    }
  }

  const resetAssetUplaoded = async (name = false) => {
    let clearData = !name
    await dispatch(removeSignAsset(!name, name))
    const prevProgressesState = assetsUploadProgresses.current
    let newData = []
    if (name) {
      newData = prevProgressesState.filter((x) => x.name !== name)
    }
    assetsUploadProgresses.current = newData
    if (!newData.length) {
      clearData = true
    }
    if (clearData) {
      setAssetType('')
      setUploadProgress(0)
      setUploading(false)
    }
  }

  const changeAssetCopyright = (copyright) => {
    triggerGoogleAnalyticsEvent('set_asset_copyright', {
      value: copyright,
      userId: user?.id,
    })
    setAssetCopyright(copyright)
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    await uploadAssetsToS3(acceptedFiles, setUploadProgress)
  }, [])

  const uploadAssetsToS3 = async (acceptedFiles, setUploadProgress) => {
    try {
      setUploading(true)
      const authorId = user.id
      triggerGoogleAnalyticsEvent('uploading_asset', {
        userId: user?.id,
      })
      const uploadData = acceptedFiles.map((file, index) => {
        const fileName = file.name

        const fileExt = fileName.substring(fileName.lastIndexOf('.'))
        const fileNameUrl = `${moment().format('x')}${index}${fileExt}`
        return {
          file,
          fileNameUrl,
        }
      })
      const prevProgressesState = assetsUploadProgresses.current
      assetsUploadProgresses.current = [
        ...prevProgressesState,
        ...uploadData.map((data) => ({
          file: data.file,
          name: data.fileNameUrl,
          progress: 0,
        })),
      ]

      const uploadedAssets = await Promise.all(
        uploadData.map((data) => {
          const uploadProgress = (value) => {
            const prevProgresses = assetsUploadProgresses.current
            setUploadProgress(value)
            const index = assetsUploadProgresses.current.findIndex(
              (x) => x.name === data.fileNameUrl,
            )
            if (index !== -1) {
              assetsUploadProgresses.current.splice(index, 1)
            }
            assetsUploadProgresses.current = [
              ...prevProgresses,
              {
                file: data.file,
                name: data.fileNameUrl,
                progress: value,
              },
            ]
          }
          return LibraryAssetUploader.saveAsLibraryAsset(
            data.file,
            data.fileNameUrl,
            `assets/${FileUtils.getFileType(data.file.type)}s`,
            authorId,
            '',
            true,
            uploadProgress,
          )
        }),
      )
      handleSaveAsset(
        uploadedAssets.map((asset, index) => ({
          fileKey: asset.key,
          fileUrl: asset.Location,
          originalFileName: acceptedFiles[index].name,
          fileType: FileUtils.getFileType(acceptedFiles[index].type),
        })),
      )
      return uploadedAssets
    } catch (error) {
      toast.error('Error uploading assets')
    } finally {
      setUploading(false)
    }
  }
  const handleSaveAsset = async (assets) => {
    await dispatch(saveAssets(assets, null, null, user.id))
  }

  const toggleUploadsModal = (value) => {
    setOpenUploadsModal(value)
  }

  return (
    <div className="assets-upload-section">
      <div className="assets-type-selection">
        <p>This agreement covers</p>
        <div className="select-radios">
          <SelectRadio
            selected={uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE}
            title="Single Asset"
            onClick={() => changeUploadAssetType(ASSET_UPLOAD_TYPE.SINGLE)}
          />
          <SelectRadio
            selected={uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE}
            title="Multiple Assets"
            onClick={() => changeUploadAssetType(ASSET_UPLOAD_TYPE.MULTIPLE)}
            // disabled={true}
          />
        </div>
      </div>
      <div className="upload-boxes">
        <UploadBox
          type={ASSET_LIBRARY.IMAGE.type}
          setAssetType={setAssetType}
          onDrop={onDrop}
          uploading={assetType === ASSET_LIBRARY.IMAGE.type}
          uploadProgress={uploadProgress}
          resetAssetUplaoded={resetAssetUplaoded}
          assetType={assetType}
          uploadAssetType={uploadAssetType}
          toggleUploadsModal={toggleUploadsModal}
          assetsUploadProgresses={assetsUploadProgresses.current}
        />
        <div className="upload-box-container" style={{ marginLeft: 10 }}>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: 10,
              marginTop: -15,
              color: '#aeaeae',
              textAlign: 'center',
            }}>
            Video upload coming soon
          </p>
          <UploadBox
            type={ASSET_LIBRARY.VIDEO.type}
            setAssetType={setAssetType}
            onDrop={onDrop}
            uploading={uploading && assetType === ASSET_LIBRARY.VIDEO.type}
            uploadProgress={uploadProgress}
            resetAssetUplaoded={resetAssetUplaoded}
            assetType={assetType}
            disabled={true}
            uploadAssetType={uploadAssetType}
            toggleUploadsModal={toggleUploadsModal}
          />
        </div>
        {/* <UploadBox
          type={ASSET_LIBRARY.PDF.type}
          setAssetType={setAssetType}
          onDrop={onDrop}
          uploading={uploading && assetType === ASSET_LIBRARY.PDF.type}
          uploadProgress={uploadProgress}
          resetAssetUplaoded={resetAssetUplaoded}
          assetType={assetType}
          disabled={true}
          uploadAssetType={uploadAssetType}
          toggleUploadsModal={toggleUploadsModal}
        />
        <UploadBox
          type={ASSET_LIBRARY.SOUND.type}
          setAssetType={setAssetType}
          onDrop={onDrop}
          uploading={uploading && assetType === ASSET_LIBRARY.SOUND.type}
          uploadProgress={uploadProgress}
          resetAssetUplaoded={resetAssetUplaoded}
          assetType={assetType}
          disabled={true}
          uploadAssetType={uploadAssetType}
          toggleUploadsModal={toggleUploadsModal}
        /> */}
      </div>
      <div className="copyright">
        <p>Add visible copyright © mark</p>
        <div className="select-radios">
          <SelectRadio
            onClick={() => changeAssetCopyright(true)}
            title="Yes"
            selected={assetCopyright}
          />
          <SelectRadio
            onClick={() => changeAssetCopyright(false)}
            title="No"
            selected={!assetCopyright}
          />
        </div>
      </div>

      <div className="next-btn">
        <button
          className="continue-btn"
          disabled={uploadProgress !== 100}
          onClick={() =>
            history.push(AGREEMENT, { template, copyright: assetCopyright })
          }>
          Continue
        </button>
      </div>

      <TikBoxModal
        open={openUploadsModal}
        maxW="600px"
        // open={true}
        toggle={toggleUploadsModal}
        title="Uploader">
        <div className="uploader-modal">
          <div className="uploader-modal__sections">
            <div className="uploader-modal__sections-section">
              <div className="title-section">
                <p className="title">
                  Images -{' '}
                  {assetsUploadProgresses?.current?.filter(
                    ({ progress }) => progress === 100,
                  )?.length || 0}
                  /{assetsUploadProgresses?.current?.length}
                </p>
                {assetsUploadProgresses?.current?.length ? (
                  <p
                    className="clear-all"
                    onClick={() => resetAssetUplaoded(null)}>
                    Clear All
                  </p>
                ) : null}
              </div>
              <div className="statuses">
                {signingAssets?.map((asset) => {
                  const progress = assetsUploadProgresses?.current.find((x) =>
                    asset.fileUrl.includes(x.name),
                  )
                  if (!progress) return null
                  const sizeTotal = getAssetInMB(progress.file?.size)
                  return (
                    <UploadBoxStatus
                      uploadAssetType={ASSET_UPLOAD_TYPE.SINGLE}
                      uploadProgress={progress.progress}
                      sizeTotal={sizeTotal}
                      resetAssetUplaoded={() =>
                        resetAssetUplaoded(progress.name)
                      }
                      toggleUploadsModal={toggleUploadsModal}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </TikBoxModal>
    </div>
  )
}

export default AssetsUploadSection
